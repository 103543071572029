.text {
  font-size: var(--font-sm);
  color: var(--text-dark-grey);
}

.pricingGrid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.root {
  padding: 16px 16px;
  position: relative;
  overflow: hidden;
  background-color: var(--bg-light-blue);
  border: 1px solid #d8d8d8;
  border-radius: var(--br-xxs);
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 12px;
}

.root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: var(--discord-blue);
  border-radius: var(--br-xxs) var(--br-xxs) 0 0;
}

.detailsWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  color: var(--theme-blue);
}

.cta {
  padding: 10px 28px;
  font-size: 14px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: var(--font-weight-medium);
  border: none;
  cursor: pointer;
  color: var(--white);
  transition: transform 0.5s ease;
  background-color: var(--theme-blue);
  flex: none;
}

.cta:hover {
  cursor: pointer;
  transform: scale(1.04);
  box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
}

.plugImage {
  object-fit: contain;
}

.subheading {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .root {
    flex-direction: column;
  }
  .plugImage {
    display: none;
  }
  .detailsWrap {
    gap: 10px;
  }
}

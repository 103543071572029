.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  padding: 16px;
  border-radius: var(--br-xxs);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.features-list-wrapper {
  display: flex;
  justify-content: center;
}

.feature {
  color: var(--black);
  font-size: var(--font-sm);
}

.featureIncluded {
  color: var(--black);
  font-size: var(--font-sm);
}

.root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: var(--discord-blue);
  border-radius: var(--br-xxs) var(--br-xxs) 0 0;
}

.heading {
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: var(--font-weight-semiBold);
  margin-top: 8px;
}

.subHeading {
  text-align: center;
  color: var(--theme-blue);
  font-weight: var(--font-weight-medium);
}

.ctaText {
  padding: 10px 28px;
  margin: auto;
  font-weight: var(--font-weight-medium);
  color: var(--dark-text-green);
  display: flex;
  align-items: center;
  gap: 4px;
}

.cta2 {
  text-align: center;
  color: var(--theme-blue);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-xs);
  text-decoration: underline;
}

.cta2:hover {
  text-decoration: none;
  cursor: pointer;
}

.pricing {
  margin: 8px auto;
}

.pricing-main {
  margin-bottom: 8px;
  position: relative;
}

.pricing-sub-text {
  font-weight: 500;
  margin-left: 5px;
}

.price {
  color: var(--theme-blue);
  display: inline-block;
  font-size: 64px;
  font-weight: bold;
  vertical-align: top;
  line-height: 0.9;
}

.star {
  font-size: var(--font-sm);
  vertical-align: super;
}

.price-meta {
  font-size: var(--font-sm);
  vertical-align: bottom;
  margin-left: 2px;
  position: absolute;
  bottom: 0px;
  width: max-content;
}

.currency {
  font-size: var(--font-xl);
  vertical-align: super;
  margin-right: 2px;
}

.text-tag {
  position: absolute;
  top: 20px;
  right: -44px;
  z-index: 1;
  width: 150px;
  padding: 4px 0;
  transform: rotate(45deg);
  font-size: var(--font-xs);
  font-weight: var(--font-weight-semiBold);
  background: linear-gradient(
    45deg,
    var(--canva-purple),
    var(--theme-blue),
    var(--discord-blue),
    var(--canva-purple)
  );
  color: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cta,
.yellow-cta {
  padding: 10px 28px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: var(--font-weight-medium);
  margin: auto;
  transition: all 0.3s ease-in-out;
}

.cta {
  color: var(--white);
  transition: transform 0.5s ease;
  background: linear-gradient(
    45deg,
    var(--canva-purple),
    var(--theme-blue),
    var(--discord-blue),
    var(--discord-blue)
  );
}

.yellow-cta {
  color: var(--black);
  transition: transform 0.5s ease;
  background-color: var(--yellow);
}

.cta:hover {
  color: var(--white);
}

.yellow-cta:hover {
  background: var(--yellow);
}

.cta:hover,
.yellow-cta:hover {
  cursor: pointer;
  transform: scale(1.04);
  box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
}

.strikethrough {
  text-decoration: line-through;
}

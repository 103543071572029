.div,
.row-div,
.col-div {
  height: var(--height, auto);
  width: var(--width, auto);
  display: var(--display, flex);
  flex-wrap: var(--flex-wrap, nowrap);
  padding: var(--padding, 0);
  margin: var(--margin, 0);
  text-align: var(--text-align, left);
  justify-content: var(--justify-content, normal);
  margin: var(--margin, 0);
}

.div.fit-content-height {
  height: fit-content;
}

.div.fit-content {
  width: fit-content;
}

.div.inline {
  display: inline-block;
}

.row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: var(--flex-wrap, nowrap);
  gap: var(--gap, 0);
  justify-content: var(--justify-content, normal);
  align-items: var(--align-items);
  align-self: var(--align-self, auto);
  flex-direction: var(--flex-direction, row);
}

.col-div {
  display: flex;
  flex-direction: column;
  gap: var(--gap, 0);
  justify-content: var(--justify-content, normal);
  align-items: var(--align-items);
}
